/* eslint-disable import/no-cycle */
import React from 'react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { useLanguage } from '../../language/language-provider';
import useQueryData from './use-query-data';
import { HeaderContext } from '../../header';
import { FooterContext } from '../../footer';
import {
    trackMainCtaFooterClick,
    trackMainCtaHeaderClick, trackMainCtaHeroClick, trackMainCtaOnPageClick,
    trackMainCtaVideoClick,
} from '../../../util/analytics';
import { HeroContext } from '../../structured-text/blocks/hero/content';
import { VideoContext } from '../video-popover';
import useDatoRouter from '../../language/use-dato-router';

export type LinkProps = {
    to: string,
    onClick?:(event: React.MouseEvent<HTMLAnchorElement>) => void,
    lang?: string,
    allowRootIfNoMatch?: boolean
    children?: string | React.ReactNode | React.ReactNode[]
    className?: string
    style?: React.CSSProperties
};

const Link = ({
    to, children, onClick, lang, allowRootIfNoMatch, ...props
}: LinkProps & Omit<React.HTMLProps<HTMLAnchorElement>, keyof LinkProps>): JSX.Element => {
    const { language } = useLanguage();
    const [path, queryString, hash] = useQueryData(to);

    const linkRef = React.useRef<HTMLAnchorElement>(null);
    const linkIsExternal = path.includes(':');

    const isWithinHeader = React.useContext(HeaderContext);
    const isWithinFooter = React.useContext(FooterContext);
    const isWithinVideo = React.useContext(VideoContext);
    const isWithinHero = React.useContext(HeroContext);

    const getRoute = useDatoRouter();

    if (linkIsExternal) {
        return (
            <a
                ref={linkRef}
                href={to}
                rel="noreferrer noopener"
                target="_blank"
                {...props}
            >
                {children}
            </a>
        );
    }

    return (
        <GatsbyLink
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={linkRef}
            to={`${getRoute(path, language)}${queryString}`}
            onClick={(e) => {
                if (linkRef?.current) {
                    const innerText = linkRef.current?.innerText;
                    const allowedValues = ['get in touch', 'get started'];
                    if (innerText && allowedValues.includes(innerText.toLowerCase().trim())) {
                        if (isWithinVideo) {
                            trackMainCtaVideoClick();
                        } else if (isWithinHero) {
                            trackMainCtaHeroClick();
                        } else if (isWithinHeader) {
                            trackMainCtaHeaderClick();
                        } else if (isWithinFooter) {
                            trackMainCtaFooterClick();
                        } else {
                            trackMainCtaOnPageClick();
                        }
                    }
                }
                if (hash) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.gatsby_router_hash = hash;
                }
                if (onClick) {
                    onClick(e);
                }
            }}
            {...props}
        >
            {children}
        </GatsbyLink>
    );
};
export default Link;

export const createLinkWithTitle = (title: string, link?: string): LinkWithTitle => [{
    title,
    link: [{
        linkExternal: link || '',
    }],
}];

export const createLinkWithoutTitle = (link?: string): LinkWithoutTitle => [{
    link: [{
        linkExternal: link || '',
    }],
}];

export const getLink = (linkData?: unknown[] | null): string => {
    if (linkData && linkData[0]) {
        if ('linkInternal' in linkData[0]) {
            return linkData[0].linkInternal.permalink;
        }
        if ('linkExternal' in linkData[0]) {
            return linkData[0].linkExternal;
        }
    }
    return '#';
};

export type LinkWithTitle = {
    title: string
    link: {
        linkExternal?: string
        linkInternal?: { permalink?: string }
    }[]
}[];

export type LinkWithoutTitle = {
    link: {
        linkExternal?: string
        linkInternal?: { permalink?: string }
    }[]
}[];

export const query = graphql`
    fragment LinkWithTitleProps on DatoCmsLinkWithTitle {
        title
        link {
            ... on DatoCmsExternalLink {
                linkExternal: link
            }
            ... on DatoCmsInternalLink {
                linkInternal: link {
                    ... on DatoCmsPage {
                        permalink
                    }
                    ... on DatoCmsArticle {
                        permalink
                    }
                    ... on DatoCmsComparison {
                        permalink
                    }
                    ... on DatoCmsCaseStudy {
                        permalink
                    }
                    ... on DatoCmsJob {
                        permalink
                    }
                    ... on DatoCmsArticleTag {
                        permalink
                    }
                    ... on DatoCmsPolicy {
                        permalink
                    }
                }
            }
        }
    }

    fragment LinkWithoutTitleProps on DatoCmsLinkWithoutTitle {
        link {
            ... on DatoCmsExternalLink {
                linkExternal: link
            }
            ... on DatoCmsInternalLink {
                linkInternal: link {
                    ... on DatoCmsPage {
                        permalink
                    }
                    ... on DatoCmsArticle {
                        permalink
                    }
                    ... on DatoCmsComparison {
                        permalink
                    }
                    ... on DatoCmsCaseStudy {
                        permalink
                    }
                    ... on DatoCmsJob {
                        permalink
                    }
                    ... on DatoCmsArticleTag {
                        permalink
                    }
                }
            }
        }
    }
`;
