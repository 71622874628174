/* eslint-disable react/jsx-props-no-spreading, react/prop-types, import/no-cycle */
import React from 'react';
import DebugPill from '../debug/debug-pill';
import { useDebug } from '../debug/debug-provider';
import FaqSet from './blocks/faq-set';
import Hero from './blocks/hero';
import HoverFeatureSetTablet from './blocks/hover-feature-set-tablet';
import HoverFeatureSetQuote from './blocks/hover-feature-set-quote';
import LogoMarquee from './blocks/logo-marquee';
import OnboardingStepSet from './blocks/onboarding-step-set';
import PageEndCta from './blocks/page-end-cta';
import QuoteCarousel from './blocks/quote-carousel';
import SolutionsCarousel from './blocks/solutions-carousel';
import EmptyBlock from './blocks/empty-block';
import ArticlesIndex from './blocks/articles-index';
import InlineCta from './blocks/inline-cta';
import MediaItemAsBlock from './blocks/media-item';
import BelowTheFoldIntroduction from './blocks/below-the-fold-introduction';
import FeatureSet from './blocks/feature-set';
import BenefitSet from './blocks/benefit-set';
import Divider from './blocks/divider';
import CustomerSuccessGrabCta from './blocks/customer-success-grab-cta';
import InDepthFeatureSet from './blocks/in-depth-feature-set';
import BenefitsGrid from './blocks/benefits-grid';
import HubspotForm from './blocks/hubspot-form';
import GetStartedForm from './blocks/get-started-form';
import UptickIsInternational from './blocks/uptick-is-international';
import UptickInNumbersSet from './blocks/uptick-in-numbers-set';
import TeamGrid from './blocks/team-grid';
import PressReleaseSet from './blocks/press-release-set';
import Pricing from './blocks/pricing';
import PoliciesList from './blocks/policies-list';
import JobsList from './blocks/jobs-list';
import LoginForm from './blocks/login-form';
import ComparisonTable from './blocks/comparison-table';
import PoweredByUptickLoginForm from './blocks/powered-by-uptick-login-form';
import CaseStudyIndex from './blocks/case-study-index';
import FeaturedCaseStudy from './blocks/featured-case-study';
import ContactCardSet from './blocks/contact-card-set';
import IntegrationsGrid from './blocks/integrations-grid';
import DownloadableListGrid from './blocks/downloadable-list-grid';
import PhotoCarousel from "./blocks/photo-carousel";
import TextColumnSet from "./blocks/text-columns";
import Capterra from "./blocks/capterra";
import SingleQuoteShowcase from "./blocks/single-quote-showcase";
import TextBlock from "./blocks/text-block";

/*
* Context for retaining render depth
* */
export const BlockRendererContext = React.createContext(-1);

export const RenderBlock = ({ __typename, ...props }: any): JSX.Element | null => {
    switch (__typename.replace('DatoCms', '')) {
        case 'Hero':
            return <Hero {...props} />;
        case 'HoverFeatureSetTablet':
            return <HoverFeatureSetTablet {...props} />;
        case 'HoverFeatureSetQuote':
            return <HoverFeatureSetQuote {...props} />;
        case 'SolutionsCarousel':
            return <SolutionsCarousel {...props} />;
        case 'OnboardingStepSet':
            return <OnboardingStepSet {...props} />;
        case 'QuoteCarousel':
            return <QuoteCarousel {...props} />;
        case 'LogoMarquee':
            return <LogoMarquee {...props} />;
        case 'FaqSet':
            return <FaqSet {...props} />;
        case 'PageEndCta':
            return <PageEndCta {...props} />;
        case 'ArticlesIndex':
            return <ArticlesIndex {...props} />;
        case 'InlineCta':
            return <InlineCta {...props} />;
        case 'MediaItem':
            return <MediaItemAsBlock {...props} />;
        case 'BelowTheFoldIntroduction':
            return <BelowTheFoldIntroduction {...props} />;
        case 'FeatureSet':
            return <FeatureSet {...props} />;
        case 'BenefitSet':
            return <BenefitSet {...props} />;
        case 'Divider':
            return <Divider {...props} />;
        case 'CustomerSuccessGrabCta':
            return <CustomerSuccessGrabCta {...props} />;
        case 'InDepthFeatureSet':
            return <InDepthFeatureSet {...props} />;
        case 'BenefitsGrid':
            return <BenefitsGrid {...props} />;
        case 'HubspotForm':
            return <HubspotForm {...props} />;
        case 'GetStartedForm':
            return <GetStartedForm {...props} />;
        case 'UptickIsInternational':
            return <UptickIsInternational {...props} />;
        case 'UptickInNumbersSet':
            return <UptickInNumbersSet {...props} />;
        case 'TeamGrid':
            return <TeamGrid {...props} />;
        case 'PressReleaseSet':
            return <PressReleaseSet {...props} />;
        case 'Pricing':
            return <Pricing {...props} />;
        case 'PoliciesList':
            return <PoliciesList {...props} />;
        case 'JobsList':
            return <JobsList {...props} />;
        case 'LoginForm':
            return <LoginForm {...props} />;
        case 'ComparisonTable':
            return <ComparisonTable {...props} />;
        case 'PoweredByUptickLoginForm':
            return <PoweredByUptickLoginForm {...props} />;
        case 'CaseStudyIndex':
            return <CaseStudyIndex {...props} />;
        case 'FeaturedCaseStudy':
            return <FeaturedCaseStudy {...props} />;
        case 'ContactCardSet':
            return <ContactCardSet {...props} />;
        case 'IntegrationsGrid':
            return <IntegrationsGrid {...props} />;
        case 'DownloadableListGrid':
            return <DownloadableListGrid {...props} />;
        case 'PhotoCarousel':
            return <PhotoCarousel {...props} />;
        case 'TextColumnSet':
            return <TextColumnSet {...props} />;
        case 'Capterra':
            return <Capterra {...props} />;
        case 'SingleQuoteShowcase':
            return <SingleQuoteShowcase {...props} />;
        case 'TextBlock':
            return <TextBlock {...props} />;
        default:
            return <EmptyBlock>{__typename}</EmptyBlock>;
    }
};

interface BlockRendererProps {
    className?: string
    data?: any;
    isBlog?: boolean
}

const BlockRenderer = ({ data, className, isBlog }: BlockRendererProps): JSX.Element => {
    const { showBlockNames } = useDebug();
    const existingRenderContextDepth = React.useContext(BlockRendererContext);

    return (
        <BlockRendererContext.Provider value={existingRenderContextDepth + 1}>
            {data && data.map((block: any) => {
                const { __typename: blockType } = block;
                return (
                    <>
                        {showBlockNames && (
                            <DebugPill css={{
                                position: 'absolute',
                            }}
                            >
                                {blockType as string}
                            </DebugPill>
                        )}
                        <RenderBlock
                            key={block.id}
                            className={className}
                            isBlog={isBlog}
                            {...block}
                        />
                    </>
                );
            })}
        </BlockRendererContext.Provider>
    );
};

export default BlockRenderer;
