import React from 'react';
import { graphql } from 'gatsby';
import Container from '../../../primitives/grid/container';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import { breakpoints } from '../../../primitives/tokens';
import Col from '../../../primitives/grid/col';
import Row from '../../../primitives/grid/row';

export type TextBlockProps = Queries.TextBlockPropsFragment;

const TextBlock = ({
    richText,
}: TextBlockProps): JSX.Element => (
    <Container css={{
        marginTop: '80px',
        marginBottom: '80px',
        [breakpoints.tb]: {
            marginTop: '54px',
            marginBottom: '54px',
        },
        [breakpoints.mb]: {
            marginBottom: '39px',
            marginTop: '39px',
        },
    }}
    >
        <Row>
            <Col breakpoints={{
                dt: { span: 1 },
                tb: { span: 1, hidden: true },
            }}
            />
            <Col breakpoints={{
                dt: { span: 10 },
                tb: { span: 12 }
            }}
            >
                <CustomStructuredTextRenderer isBlog data={richText} />
            </Col>
        </Row>
    </Container>
);

export default TextBlock;

export const query = graphql`
    fragment TextBlockProps on DatoCmsTextBlock {
        richText {
            blocks
            links
            value
        }
    }
`;
